import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://www.westernstatesacquirers.com/index.php"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "275px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAMC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwAB/9oADAMBAAIQAxAAAAGcxT2LP//EABYQAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAQABBQJdMgL/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BV//EABgQAAIDAAAAAAAAAAAAAAAAAAABITEy/9oACAEBAAY/AslEpn//xAAZEAEAAgMAAAAAAAAAAAAAAAABACERMYH/2gAIAQEAAT8hpEcmFKtwpaZ//9oADAMBAAIAAwAAABDwP//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxBBsX//xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QThR//8QAGRABAQEBAQEAAAAAAAAAAAAAARExACFR/9oACAEBAAE/EAKWZXpxnJuCZ86PuLRM7//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "wsaa",
            "title": "wsaa",
            "src": "/static/7dae2718dd1ba84c255c3d91647a69df/6a9d4/wsaa.jpg",
            "srcSet": ["/static/7dae2718dd1ba84c255c3d91647a69df/8675c/wsaa.jpg 100w", "/static/7dae2718dd1ba84c255c3d91647a69df/3cc80/wsaa.jpg 200w", "/static/7dae2718dd1ba84c255c3d91647a69df/6a9d4/wsaa.jpg 275w"],
            "sizes": "(max-width: 275px) 100vw, 275px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`CWA is heading to Henderson. NV and the M Resort for the `}<a parentName="p" {...{
        "href": "https://www.westernstatesacquirers.com/attendee-registration.php"
      }}>{`Annual WSAA Conference`}</a>{`.  With sponsors from all sectors of our industry, this event is sure to be informative and provide for excellent networking opportunities.`}</p>
    <h3>{`MUST SEE SEMINARS`}</h3>
    <h3>{`Wednesday`}</h3>
    <h4>{`Field Guide for ISO’s: Sponsored by American Express`}</h4>
    <p>{`11 AM    Introductions: Mark Dunn, Field Guide Enterprises`}</p>
    <p>{`11:10 AM  EMV ‐ That Ship has not sailed, you can still get on board: Mark Dunn, Field Guide Enterprises`}</p>
    <p>{`11:50 AM  How artificial intelligence can ensure required compliance: Neil Jones, Rippleshot`}</p>
    <p>{`12:30 PM   Selling value added products: Marc Beauchamp, PayProTec`}</p>
    <p>{`2:10 PM    Alternative lending ‐ How ISO’s make money: Scott Griest, Rapid Capital Funding`}</p>
    <p>{`2:50 PM    Cracking the medical payments market: Mary Winingham, Mirror Consulting`}</p>
    <h3>{`Thursday`}</h3>
    <h4>{`The Technology Bet`}</h4>
    <p>{`Ken Elderts, VP, ISO Sales, AnywhereCommerce`}</p>
    <p>{`Cory Capoccia, President, Womply`}</p>
    <p>{`John Priore, President and CEO, Priority Payment Systems`}</p>
    <p>{`Moderator: Xavier Ayala, CEO, Managing Partner, ONESOURCE Business Advisors. President, WSAA`}</p>
    <p>{`This dynamic panel of experts will discuss payment technology innovation geared towards enhancing the merchant and consumer payments experience. Discover which technologies merchants and consumers are adopting and how retailers are reducing friction at the POS and using big data to understand their businesses and customers better to increase customer loyalty. Learn how payment technology is becoming more intuitive, transparent, and enhancing the merchant and consumer experience.`}</p>
    <h4>{`How to Manage Through ISO Growing Pains ‐ Hit, Stay, or Fold?`}</h4>
    <p>{`Michael Cottrell, Director, Global Sales and Marketing, ProPay`}</p>
    <p>{`Jeremy Wing, President, Payscape`}</p>
    <p>{`Donna Embry, Chief Payments Advisor, Payment Alliance International`}</p>
    <p>{`Moderator: Deana Rich, CEO, Deana Rich Consulting`}</p>
    <p>{`During this session a respected panel of industry leaders will discuss the life cycle of the ISO, focusing on major decisions that often must be faced in growing your business. Is registration truly required to be successful? Is a retail model the best solution, or is wholesale the answer? What are the implications of the liability versus no‐liability landscape?`}</p>
    <p>{`For more details regarding the agenda of the event and specific sponsors, `}<a parentName="p" {...{
        "href": "https://www.westernstatesacquirers.com/uploads/pdf/agenda/wssa_conference_agenda_2015.pdf"
      }}>{`CLICK HERE.`}</a></p>
    <p>{`If you will be in Las Vegas and would like to meet with a member of our team, please email `}<a parentName="p" {...{
        "href": "mailto:jason.sylvan@cwams.com"
      }}>{`CWA Partnerships`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      